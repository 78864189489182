import React, { useCallback, useContext } from 'react';
import {useFormState} from 'react-final-form';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  ReferenceField,
  TopToolbar,
  EditButton,
  DeleteButton,
  ShowButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ImageInput,
  ImageField,
  PasswordInput,
  required,
  minLength,
  Create,
  SimpleShowLayout,
  Show,
  SaveButton,
  ReferenceInput,
} from 'react-admin';
import dataProvider from './dataProvider';
import AvatarField from "./AvatarField";
import UserForm from "./UserForm";
import { AppContext } from './AppContext';

export const UploadAvatarButton = props => {
  const formState = useFormState();
  const { currentUserForm, setUpatedAvatar } = useContext(AppContext);

  const handleClick = useCallback(() => {
      const avatar = formState.values.avatar;
      dataProvider.uploadAvatar('media_objects', {data: {'avatar': avatar}}, props.id);
      if(currentUserForm) {
        setUpatedAvatar(true);
      }
  });

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick}/>;
};

const EditProfileButton = ({...props}) => {
  return (
    <>
      {props.record.roleId === 1 ? null : <EditButton {...props}/>}
    </>
  );
}

const DeleteProfileButton = ({...props}) => {
  return (
    <>
      {props.record.id == localStorage.getItem('id') ? null : <DeleteButton {...props}/>}
    </>
  );
}

export const UserList = ({permissions, ...props}) => {

  return (<List {...props} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id"/>
        <EmailField source="email"/>
        <ReferenceField
          label="Role"
          source="roleId"
          reference="roles"
          link={false}
        >
          <TextField source="label"/>
        </ReferenceField>
        <DateField source="created"/>
        <DateField source="updated"/>
        {permissions === 'ROLE_SUPER_ADMIN' || permissions === 'ROLE_1' ? <EditProfileButton/> : null}
        <ShowButton/>
        {permissions === 'ROLE_SUPER_ADMIN' ? <DeleteProfileButton/> : null}
      </Datagrid>
    </List>
  );
}

const UserName = ({record}) => {
  return <span>User {record ? `"${record.email}"` : null}</span>;
};

export const UserRoleSelectInput = ({...props}) => {

  if((localStorage.getItem('role') === 'ROLE_1') === true) {

    props.choices = props.choices.filter(function( obj ) {
      return obj.name !== 'ROLE_SUPER_ADMIN';
    });
  }

  return (
    <SelectInput {...props} optionText="label" optionValue="id" validate={false}/>
  )
}

export const UserRoleSelect = ({...props}) => {

  return (
    <ReferenceInput {...props} label="Role" source="id" reference="roles">
        <UserRoleSelectInput/>
    </ReferenceInput>
  );
};

export const UserEdit = ({permissions, ...props}) => {

  return (
    <Edit title={<UserName/>} {...props}>
      <UserForm {...props}/>
    </Edit>
  );
}

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="../users">
      <TextInput source="email" type="email" validate={[required(), minLength(1)]}/>
      <PasswordInput source="password" validate={[required(), minLength(1)]}/>
      <UserRoleSelect/>
      <TextInput source="firstName"/>
      <TextInput source="secondName"/>
      <TextInput source="phone"/>
      <TextInput source="position"/>
      <TextInput source="companyName"/>
      <TextInput source="companyAddress1"/>
      <TextInput source="companyAddress2"/>
      <TextInput source="companyZip"/>
      <TextInput source="companyCity"/>
      <TextInput source="companyCountry"/>
      <ImageInput source="avatar" label="Avatar" accept="image/*">
        <ImageField source="avatar" title="title"/>
      </ImageInput>
      <UploadAvatarButton label="Upload avatar"/>
    </SimpleForm>
  </Create>
);

const UserShowActions = ({...props}) => {
  return (
  <TopToolbar>
     {/* <EditButton></EditButton> */}
  </TopToolbar>)
};

export const UserShow = props => {
  return (<Show {...props} hasEdit={false} actions={<UserShowActions/>} >
      <SimpleShowLayout >
        <TextField source="id"/>
        <TextField source="email"/>
        <ReferenceField
          label="Role"
          source="roleId"
          reference="roles"
          link={false}
        >
          <TextField source="label"/>
        </ReferenceField>
        <TextField source="firstName"/>
        <TextField source="secondName"/>
        <TextField source="phone"/>
        <TextField source="position"/>
        <TextField source="companyName"/>
        <TextField source="companyAddress1"/>
        <TextField source="companyAddress2"/>
        <TextField source="companyZip"/>
        <TextField source="companyCity"/>
        <TextField source="companyCountry"/>
        <AvatarField id={props.id}/>
      </SimpleShowLayout>
    </Show>
  )
};
