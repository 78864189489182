import React, { useContext, useEffect } from 'react';
import {
  DeleteButton,
  TextInput,
  ImageInput,
  ImageField,
  required,
  minLength,
  SaveButton,
  ReferenceInput,
  FormWithRedirect,
} from 'react-admin';
import {UploadAvatarButton, UserRoleSelectInput} from './users';
import {Typography, Box, Toolbar } from '@material-ui/core';
import { AppContext } from './AppContext';

const UserForm = ({permissions, ...props}) => {
  const { setCurrentUserForm } = useContext(AppContext);
  const userId = Number(localStorage.getItem('id'));

  if('object' === (typeof props.record.userRole)) {
    props.record.userRole = props.record.roleId;
  }

  useEffect(() => {
    if(userId === props.record.id) {
      setCurrentUserForm(true)
    } else {
      setCurrentUserForm(false)
    }
  }, []);

  return (
    <FormWithRedirect
        {...props}
        render={formProps => (
            <form>
                <Box p="1em">
                    <Box display="flex" flexWrap="wrap">
                        <Box flex={2} mx="0.5em" mr="1em">
                            <Typography variant="h6" gutterBottom>Identity:</Typography>
                            <Box display="flex" flexWrap="wrap">
                                <Box flex={1} mx="0.5em" css={{ minWidth: 280}}>
                                  <TextInput disabled fullWidth source="id"/>
                                </Box>
                                <Box flex={1} mx="0.5em" css={{ minWidth: 280}}>
                                  <ReferenceInput label="Role" source="roleId" reference="roles" fullWidth>
                                      <UserRoleSelectInput/>
                                  </ReferenceInput>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" mx="0.5em">
                              <TextInput source="email" validate={[required(), minLength(1)]} fullWidth/>
                            </Box>
                            <Box display="flex" flexWrap="wrap">
                                <Box flex={1} mx="0.5em" css={{ minWidth: 280}}>
                                  <TextInput source="firstName" fullWidth/>
                                </Box>
                                <Box flex={1} mx="0.5em" css={{ minWidth: 280}}>
                                  <TextInput source="secondName" fullWidth/>
                                </Box>
                            </Box>
                            <Box display="flex" flexWrap="wrap">
                                <Box flex={1} mx="0.5em" css={{ minWidth: 280}}>
                                  <TextInput source="phone" fullWidth/>
                                </Box>
                                <Box flex={1} mx="0.5em" css={{ minWidth: 280}}>
                                  <TextInput source="position" fullWidth/>
                                </Box>
                            </Box>
                            <Typography variant="h6" gutterBottom>Address:</Typography>
                            <Box display="flex" flexDirection="column" mx="0.5em">
                              <TextInput source="companyName" fullWidth/>
                              <TextInput source="companyAddress1" multiline fullWidth/>
                              <TextInput source="companyAddress2" multiline fullWidth/>
                            </Box>
                            <Box display="flex" flexWrap="wrap">
                                <Box flex={1} mx="0.5em" css={{ minWidth: 200}}>
                                  <TextInput source="companyZip" fullWidth/>
                                </Box>
                                <Box flex={1} mx="0.5em" css={{ minWidth: 200}}>
                                  <TextInput source="companyCity" fullWidth/>
                                </Box>
                                <Box flex={1} mx="0.5em" css={{ minWidth: 200}}>
                                <TextInput source="companyCountry" fullWidth/>
                                </Box>
                            </Box>
                            <Toolbar>
                              <Box display="flex" justifyContent="center" width="100%">
                                  <SaveButton
                                            saving={formProps.saving}
                                            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                            style={{width: '304px', marginBottom: '30px', marginTop: '10px'}}
                                  />
                                  {/* <DeleteButton record={formProps.record} />                   */}
                                </Box>
                            </Toolbar>
                        </Box>
                        <Box flex={2} mx="0.5em" css={{ minWidth: 300}}>
                            <Box display="flex" alignItems="center" flexDirection="column" mx="0.5em">
                              <Box css={{ minWidth: 200, maxWidth: 320}}>
                                <Typography variant="h6" gutterBottom>Avatar:</Typography>
                                <Box mx="0.5em">
                                  <ImageInput source="avatar" label="" accept="image/*">
                                    <ImageField source="avatar" title="title"/>
                                  </ImageInput>
                                </Box>
                                <Box mx="0.5em" >
                                    <UploadAvatarButton id={props.id} style={{width: "100%"}} label="Save avatar"/>
                                </Box>
                              </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </form>
        )}
    />
  )};

  export default UserForm;
