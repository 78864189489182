import React from 'react';
import QRCode from 'qrcode.react';
import {
  List,
  Filter,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceArrayField,
  ReferenceManyField,
  Pagination,
  SingleFieldList,
  ChipField,
  DateField,
  ShowButton,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  minLength,
  maxLength,
  Show,
  SimpleShowLayout,
  ArrayField,
  Create,
  Pagination as RaPagination,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const customStyles = makeStyles({
  toolbar: {
    display: 'none'
  },
  toolbarMargin: {
    marginBottom: '-10px'
  }
});

const visibilityUpdatesNumber = 4;

export const ProductUpdatesPaginationActions = () => '';

export const ProductUpdatesPagination = props => {
  const classes = customStyles();

  if (props.total > visibilityUpdatesNumber) {
    return (
      <RaPagination {...props} classes={{toolbar: classes.toolbarMargin}}
                    rowsPerPageOptions={[visibilityUpdatesNumber, props.total]}
                    ActionsComponent={ProductUpdatesPaginationActions} labelRowsPerPage={"Visible records:"}/>
    );
  } else {
    return (
      <RaPagination {...props} classes={{toolbar: classes.toolbar}} rowsPerPageOptions={[]}
                    ActionsComponent={ProductUpdatesPaginationActions}/>
    );
  }
}

export const ProductList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="name"/>
      <TextField source="certificates"/>
      <ReferenceManyField
        label="Product Updates"
        reference="product_updates"
        target="product"
        sort={{field: 'created', order: 'DESC'}}
        sortable={false}
        perPage={visibilityUpdatesNumber}
        pagination={<ProductUpdatesPagination/>}
      >
        <SingleFieldList linkType="show">
          <ChipField source="created"/>
        </SingleFieldList>
      </ReferenceManyField>
      <ShowButton/>
    </Datagrid>
  </List>
);

export const ProductShow = props => {
  let host = "444" === window.location.port ? `https://${document.domain}` : `https://${document.domain}`;
  const productUrl = `${host}/public/product/${props.id}`.replace('admin.', '');

  const downloadQR = () => {
    const canvas = document.getElementById("qr-code");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `product_id_${props.id}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div>
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id"/>
          <TextField source="name"/>
          <TextField source="certificates"/>
          <ReferenceManyField
            pagination={<Pagination/>}
            label="Product Updates"
            reference="product_updates"
            target="product"
          >
            <Datagrid>
              <TextField source="id"/>
              <TextField source="process"/>
              <TextField source="address1" sortable={false}/>
              <TextField source="address2" sortable={false}/>
              <TextField source="zip" sortable={false}/>
              <TextField source="city" sortable={false}/>
              <TextField source="country" sortable={false}/>
              <DateField source="created"/>
            </Datagrid>
          </ReferenceManyField>
        </SimpleShowLayout>
      </Show>

      <div className="qr-wrapper">
        <QRCode
          id="qr-code"
          value={productUrl}
          size={200}
          includeMargin={false}
        />
        <Button onClick={downloadQR} color="primary" className="qr-button">Download QR code</Button>
      </div>
    </div>
  );
}

export const ProductCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()}/>
      <TextInput source="certificates"/>
      <ArrayInput source="productUpdates" validate={[required(), minLength(1), maxLength(1)]} label="Product Update">
        <SimpleFormIterator disableRemove>
          <TextInput source="process" label="Process"/>
          <TextInput source="address1" label="Address1"/>
          <TextInput source="address2" label="Address2"/>
          <TextInput source="zip" label="Zip"/>
          <TextInput source="city" label="City"/>
          <TextInput source="country" label="Country"/>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
