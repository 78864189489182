import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceArrayField,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  DateField,
  ShowButton,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleShowLayout,
  required,
  Create
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

export const ProductUpdateList = props => (
  <List {...props} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id" label="ID"/>
        <ReferenceField
          label="Product Name"
          source="productId"
          reference="products"
          sortBy="product.name"
          link="show"
        >
          <TextField source="name"/>
        </ReferenceField>
        <TextField source="process"/>
        <DateField source="created"/>
        <ShowButton/>
      </Datagrid>
    </List>
);

const customStyles = makeStyles({
  root: {
    '& label': {
      color: '#000',
      fontWeight: '600'
    },
  },
});

export const ProductUpdateShow = props => {
  const classes = customStyles();
  return (<Show classes={classes} {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID"/>
        <ReferenceField
          label="Product Name"
          source="productId"
          reference="products"
          link="show"
        >
          <TextField source="name"/>
        </ReferenceField>
        <TextField source="process"/>
        <TextField source="address1"/>
        <TextField source="address2"/>
        <TextField source="zip"/>
        <TextField source="city"/>
        <TextField source="country"/>
        <DateField source="created"/>
      </SimpleShowLayout>
    </Show>
  )
};

export const ProductUpdateCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="process"/>
      <TextInput source="address1"/>
      <TextInput source="address2"/>
      <TextInput source="zip"/>
      <TextInput source="city"/>
      <TextInput source="country"/>
      <ReferenceInput label="Product" source="product" reference="products" validate={[required()]}>
        <SelectInput optionText="name"/>
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
