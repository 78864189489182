import * as React from "react";
import { Route } from 'react-router-dom';
import profile from "./profile";
import contactForm from "./message";
import paymentsShow from "./payment";
import iframePage from "./IframePage";

export default [
  <Route exact path="/my-profile" component={profile.edit} />,
  <Route exact path="/contact_form" component={contactForm.edit} />,
  <Route exact path="/payments" component={paymentsShow.list} />,
  <Route exact path="/dashboard" component={iframePage} />
];
