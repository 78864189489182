import React, { createContext, useState } from 'react';

export const AppContext = createContext();

const AppProvider = ({children}) => {
  let [userAvatar, setUserAvatar] = useState('');
  let [isUpdatedAvatar, setUpatedAvatar] = useState(false);
  let [currentUserForm, setCurrentUserForm] = useState(false);

  return (
    <AppContext.Provider value={{userAvatar, setUserAvatar, isUpdatedAvatar, setUpatedAvatar, currentUserForm, setCurrentUserForm}}>
      {children}
    </AppContext.Provider>
  )
};

export default AppProvider;