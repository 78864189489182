import React from 'react';
import {
  TextInput,
  SimpleForm,
  required,
  number,
  minValue,
  email,
  Create,
  Toolbar,
  SaveButton
} from 'react-admin';

const ContactForm = ({staticContext, ...props}) => {
  const SendButton = (props) => {
 
    return (
      <Toolbar {...props} >
        <SaveButton {...props} redirect="/contact_form" label="Send message"/>
      </Toolbar>
    )
  }
  
  return (
    <Create {...props}
      resource="message/contact_form"
      basePath="/contact_form"
      title="Contact form"
      successMessage="Message has been sent"
      {...props}
    >
      <SimpleForm toolbar={<SendButton />}>
          <TextInput source="subject" defaultValue="New network installation" validate={[required()]} resettable/>
          <TextInput source="total_nodes" label="Total number of nodes" validate={[required(), number(), minValue(1, "The minimum value is 1")]} resettable/>
          <TextInput source="owned_nodes" label="Owned number of nodes" validate={[required(), number(), minValue(1, "The minimum value is 1")]} resettable/>
          <TextInput source="contact_email" label="Contact email" validate={[required(), email("incorrect email address")]} resettable/>
          <TextInput source="message" label="Message" validate={[required()]} rows={5} multiline resettable/>
      </SimpleForm>
    </Create>
  );
};

export default ContactForm;
