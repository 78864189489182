import {GET_ONE, UPDATE, fetchUtils} from "react-admin";
import {stringify} from "query-string";

const apiUrl = process.env.REACT_APP_API_ENTRYPOINT + "/api";
const fetchJson = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: "application/vnd.api+json"}); // Refer to https://jsonapi.org/
  }
  // add your own headers here
  options.headers.set(
    "Authorization",
    `Bearer ${window.localStorage.getItem("token")}`
  );
  return fetchUtils.fetchJson(url, options);
};

const avatarFilter = (item) => {
  if (undefined !== item) {
        if (undefined !== item.url) {
    }
    if (undefined !== item.avatar) {
      item.avatar.data.url = `${item.avatar.data.id}`;
      item.avatar = idfix({...item.avatar});
    }
  }
  return item;
}

//dirty way, should be handled in backend
const idfix = (item) => {
  for (let i in item) {
    var obj = item[i];
    if (undefined !== obj && undefined !== obj.id) {
      obj._id = parseInt(obj.id.split('/').reverse()[0]);
    }
  }
  return item;
}
const mapResponseToInternal = (item) => {
  if(undefined !== item) {
    let avatar = avatarFilter(item.relationships);
    let $return = {
      id: item.attributes._id,
      ...item.attributes
    };
    if (undefined !== avatar) {
      $return = {...$return, ...avatar}
    }
    return $return;
  }
};

export default {
  getList: (resource, params) => {
    console.debug("Using getList() ...");
    const {page, perPage} = params.pagination;
    const {field, order} = params.sort;

    const query = {
      page: JSON.stringify(page),
      itemsPerPage: JSON.stringify(perPage),
      // filter: JSON.stringify(params.filter),// not supported ATM
      [`order[${field}]`]: order,
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return fetchJson(url).then(({headers, json}) => ({
      data: json.data.map((item) => mapResponseToInternal(item)),
      total: json.meta.totalItems,
    }));
  },

  getOne: (resource, params) =>
    fetchJson(`${apiUrl}/${resource}/${params.id}`).then(({json}) => ({
      data: mapResponseToInternal(json.data),
    })),

  getMany: (resource, params) => {
    console.debug("Using getMany() ...");
    const query = {
      filter: JSON.stringify({id: params.ids}),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return fetchJson(url).then(({json}) => ({
      data: json.data.map((item) => mapResponseToInternal(item)),
    }));
  },

  getManyReference: (resource, params) => {
    console.debug("Using getManyReference() ...");
    const {page, perPage} = params.pagination;
    const {field, order} = params.sort;
    const query = {
      page: JSON.stringify(page),
      itemsPerPage: JSON.stringify(perPage),
      //     filter: JSON.stringify({// not supported ATM
      //         ...params.filter,
      //     }),
      [`order[${field}]`]: order,
      [params.target]: params.id,
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return fetchJson(url).then(({headers, json}) => ({
      data: json.data.map((item) => mapResponseToInternal(item)),
      total: json.meta.totalItems,
    }));
  },

  update: (resource, params) => {
    let data = params.data;

    if(resource === 'users' && params.data.avatar !== undefined) {
      delete data["avatar"];
    }

    return fetchJson(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(data),
    }).then(({json}) => ({data: json}
    ));
  },

  updateMany: (resource, params) => {
    // TODO in the backend
    const query = {
      filter: JSON.stringify({id: params.ids}),
    };
    return fetchJson(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({json}) => ({data: json}));
  },

  create: (resource, params) =>
    fetchJson(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({json}) => ({
      data: mapResponseToInternal(json.data),
    }
    )),

  delete: (resource, params) =>
    fetchJson(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({json}) => ({data: params})),

  deleteMany: (resource, params) => {
    // TODO in the backend
    const query = {
      filter: JSON.stringify({id: params.ids}),
    };
    return fetchJson(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({json}) => ({data: json}));
  },

  // Custom functions for dataprovider
  getUserIdAndRole: (resource, params) =>
    fetchJson(`${apiUrl}/${resource}/${params.id}`).then(({json}) => ({
      id: json.id,
      role: json.roles,
    })),

  uploadAvatar: (resource, params, userId) => {
    const picture = params.data.avatar.rawFile;

    const formData = new FormData();
    formData.append("avatar", picture);

    if (typeof userId !== "undefined") {
      formData.append("user_id", userId);
    }

    fetchJson(`${apiUrl}/${resource}`, {
      method: "POST",
      body: formData,
    }).then(({json}) => ({
      data: mapResponseToInternal(json.data),
    }));
  },
};
