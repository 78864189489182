/** Custom implementation. **/
import ProductIcon from '@material-ui/icons/ShoppingCart';
import ProductUpdateIcon from '@material-ui/icons/ListAlt';
import UserIcon from '@material-ui/icons/Group';
import React from 'react';
import {Admin, Resource} from 'react-admin';
import {ProductList, ProductShow, ProductCreate} from './products';
import {ProductUpdateList, ProductUpdateShow} from './productUpdates';
import {UserList, UserEdit, UserCreate, UserShow} from './users';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import './assets/styles.css';
import Footer from './Footer';
import CustomLayout from './CustomLayout';
import customRoutes from "./customRoutes";
import {PaymentEdit, PaymentShow} from "./payment/payments";
import theme from "./theme";
import AppProvider from "./AppContext";

const App = () => {
  return (
  <React.Fragment>
    <AppProvider>
      <Admin theme={theme} authProvider={authProvider} dataProvider={dataProvider}
             customRoutes={customRoutes} layout={CustomLayout}>
        {permissions => [
          <Resource name="products" options={{ label: 'Products' }} icon={ProductIcon} list={ProductList} create={permissions === 'ROLE_1' ? ProductCreate : null} show={ProductShow}/>,
          <Resource name="product_updates" options={{ label: 'Product updates' }} icon={ProductUpdateIcon} list={ProductUpdateList} show={ProductUpdateShow}/>,
          <Resource name="users" options={{ label: 'Users' }} icon={UserIcon} list={UserList} edit={permissions === 'ROLE_SUPER_ADMIN' || permissions === 'ROLE_1' ? UserEdit : null} show={UserShow} create={permissions === 'ROLE_SUPER_ADMIN' || permissions === 'ROLE_1' ? UserCreate : null}/>,
          <Resource name="payments" edit={PaymentEdit} show={PaymentShow}/>,
          <Resource name="media_objects"/>,
          <Resource name="profile" />,
          <Resource name="roles" />
        ]}
      </Admin>
    </AppProvider>
    <Footer/>
  </React.Fragment>
)};

export default App;
