import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ShowButton,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  BooleanInput,
  Toolbar,
  SimpleShowLayout,
  Show,
  SaveButton
} from 'react-admin';


export const PaymentList = ({staticContext, ...props}) => { 
  return (
      <List resource="payments"
            basePath="/payments"
            title="Payments list"
            bulkActionButtons={false}
            hasCreate={false}
            hasShow={true}
            hasEdit={true}
            hasList={true}
            {...props}
      >
      <Datagrid>
        <TextField source="title"/>
        <TextField source="payValue" label="Pay value (in PLN)"/>
        <DateField source="createdAt" label="Created"/>
        <DateField source="paidAt" label="Paid"/>
        <EditButton/>
        <ShowButton/>
      </Datagrid>
    </List>
  );
}

export const PaymentShow = ({permissions, ...props}) => {
  return (
    <Show title="Payment details"
          resource="payments"
          basePath="/payments"
          {...props}
    >
      <SimpleShowLayout>
        <TextField source="id"/>
        <TextField source="title"/>
        <TextField source="payValue" label="Pay value (in PLN)"/>
        <DateField source="createdAt" label="Created"/>
        <DateField source="paidAt" label="Paid"/>
        <EditButton/>
      </SimpleShowLayout>
    </Show>
  );
}

const PaymentName = ({record}) => {
  return <span>{record ? `${record.title}` : null}</span>;
};

const SaveButtonForEditPage = props => (
  <Toolbar {...props} >
    <SaveButton {...props}/>
  </Toolbar>
);

export const PaymentEdit = ({permissions, ...props}) => {
  return (
    <Edit
      title={<PaymentName/>}
      resource="payments"
      basePath="/payments"
      {...props}
    >
      <SimpleForm toolbar={<SaveButtonForEditPage />}>
        <TextInput disabled source="id"/>
        <TextInput disabled source="title"/>
        <TextInput disabled source="payValue" label="Pay value (in PLN)"/>
        <DateInput disabled source="createdAt" label="Created"/>
        <BooleanInput source="paidAt" label="We have already paid" />
      </SimpleForm>
    </Edit>
  );
}
