import React from "react";
import { connect } from "react-redux";
import { crudGetOne, UserMenu, MenuItemLink } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import EmailIcon from '@material-ui/icons/Email';
import PaymentIcon from '@material-ui/icons/Payment';
import UserInfo from "./UserInfo";
import { usePermissions } from 'react-admin';
import UserAvatar from './UserAvatar'; 


const CustomUserMenuView = ({ crudGetOne, profile, ...props }) => {
  const { permissions } = usePermissions();
  
  return (
      <React.Fragment >
        <UserMenu
          label={profile ? profile.nickname : ""}
          {...props}
          icon={<UserAvatar />}
        >
          <MenuItemLink
            to="/my-profile"
            primaryText="My profile"
            leftIcon={<SettingsIcon />}
          />
          <MenuItemLink
            to="/contact_form"
            primaryText="Contact form"
            leftIcon={<EmailIcon />}
          />
          {permissions === 'ROLE_1' &&
          <MenuItemLink
            to="/payments"
            primaryText="Payments"
            leftIcon={<PaymentIcon/>}
          />}
        </UserMenu>
        <UserInfo />
      </React.Fragment>
    );
}

const mapStateToProps = (state) => {
  const resource = "profile";
  const id = "my-profile";

  return {
    profile: state.admin.resources[resource]
      ? state.admin.resources[resource].data[id]
      : null,
  };
};

const CustomUserMenu = connect(mapStateToProps, { crudGetOne })(
  CustomUserMenuView
);
export default CustomUserMenu;
