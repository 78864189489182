import React from 'react';

const UserInfo = () => {
  const username = localStorage.getItem("username")

  return (
    <div className="user-info">
      {username}
    </div>
  );
}

export default UserInfo;



