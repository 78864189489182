import React from 'react';

const IframePage = () => {
  let host = "444" === window.location.port ? `http://${document.domain}:3000` : `https://${document.domain}`;
  const boardUrl = `${host}`.replace('admin.', 'board.');
  return (
    <iframe id="node-panel"
        title="Ethereum Network Dashboard"
        width="100%"
        height="100%"
        src={boardUrl}>
    </iframe>
  );
}

export default IframePage;
