import React, { useEffect, useContext } from "react";
import Avatar from "@material-ui/core/Avatar";
import { AppContext } from './AppContext';

const UserAvatar = () => {
  const { isUpdatedAvatar, setUpatedAvatar, userAvatar, setUserAvatar } = useContext(AppContext);
  const userId = localStorage.getItem("id");
  let host = "444" === window.location.port ? `https://${document.domain}:8443` : `https://${document.domain}`;
  const apiUrl = `${host}`.replace('admin.', 'api.');
  const token = localStorage.getItem("token");

  const getUserAvatar = async() => {
    await fetch(`${apiUrl}/api/users/${userId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.avatar !== undefined) {
        setUserAvatar(apiUrl + data.avatar.fileUrl);
        setUpatedAvatar(false);
      }
    })
  }

  useEffect(() => {
    getUserAvatar();
  }, [isUpdatedAvatar]);

  return (
    <Avatar src={`${userAvatar}`} style={{height: "40px", width: "40px"}}/>
  )
}

export default UserAvatar;
