import dataProvider from "./dataProvider";

const authProvider = {
  login: ({username, password}) => {
    let formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('username', username);
    formData.append('password', password);
    formData.append('client_id', process.env.REACT_APP_API_CLIENT_ID);
    formData.append('client_secret', process.env.REACT_APP_API_CLIENT_SECRET);
    formData.append('scope', 'api_user');

    const request = new Request(process.env.REACT_APP_API_ENTRYPOINT + '/oauth/v2/token', {// TODO in .env
      method: 'POST',
      body: formData,
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({access_token, roles}) => {
        localStorage.setItem('username', username);
        localStorage.setItem('token', access_token);
        localStorage.setItem('role', roles);

        return dataProvider
          .getUserIdAndRole('users/email', {id: localStorage.getItem('username')})
          .then(response => {
            localStorage.setItem('id', response.id);
            localStorage.setItem('role', response.role[0]);
          });
        // window.location.reload();// workaround for issue: https://stackoverflow.com/questions/57368366/jwt-token-not-found-when-i-am-trying-to-access-secured-endpoints-with-apl-platfo
      });
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    localStorage.removeItem('id');
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject();
  },
  checkError: (error) => {
    const status = error.status || error.response.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('role');
      localStorage.removeItem('id');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const role = localStorage.getItem('role');
    return role ? Promise.resolve(role) : Promise.reject();
  },
  // getPermissions: params => Promise.resolve(),// TODO: to check user permissions. It’s useful to enable or disable features on a per user basis.
  // see https://marmelab.com/react-admin/Authentication.html
};

export default authProvider;
