import React from 'react';
import {
  Edit,
} from 'react-admin';
import UserForm from "../UserForm";

const ProfileEdit = ({formData, staticContext, ...props }) => {
  const userId = localStorage.getItem('id');

  return (
    <Edit
      id={userId}
      resource="users"
      basePath="/my-profile"
      redirect="../users"
      title="My profile"
      {...props}
    >
      <UserForm {...props}/>
    </Edit>
  );
};

export default ProfileEdit;
