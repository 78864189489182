import React from 'react';
import logo from "./assets/images/onecredible_logo.jpg";
import footerBanner from "./assets/images/footer_banner.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__copy">
        <img className="logo" alt="onecredible by createIT" src={logo}/>
        <p>© 2021 All rights reserved. Design & Development by <a href="https://www.createit.com/" target="_blank">createIT</a></p>
      </div>
      <img className="footer__banner" alt="EU banner" src={footerBanner}/>
    </div>
  );
}

export default Footer;
