import { createMuiTheme } from '@material-ui/core/styles';
const customTheme = createMuiTheme();

const theme = createMuiTheme({
    palette: {
        secondary: {
          main: '#fff',
        },
      },
      overrides: {
        paper: {
          width: '100%',
          overflowX: 'auto',
        },
        MuiAppBar: {
          root: {
            color: '#000',
            paddingRight: '0 !important',
    
            '& .MuiIconButton-root': {
              padding: '0 !important',
              margin: '10px',
              color: '#fff',
              marginRight: '13px',
              marginLeft: '17px',
    
              '&:hover': {
                backgroundColor: '#cd2653',
    
                '& svg': {
                  color: '#fff',
                }
              },
            },
    
            //Hide refresh butotn
            '& .MuiIconButton-root[title="Refresh"]': {
              display: 'none'
            },
    
            '& .MuiCircularProgress-svg': {
              display: 'none'
            },
            //End
    
            '& .MuiToolbar-dense': {
              minHeight: '83px',
            },
    
            '& .MuiToolbar-root': {
              color: '#000',
              backgroundColor: '#fff',
              paddingRight: '0',
    
              [customTheme.breakpoints.down('sm')]: {
                paddingTop: '26px',
              },
            },
          }
        },
        RaAppBar: {
          menuButton: {
            background: '#cd2653',
            border: '2px solid transparent !important',
    
            '&:hover': {
              border: '2px solid #cd2653 !important',
              background: '#fff !important',
    
              '& svg': {
                color: '#cd2653 !important',
              }
            },
    
            '& svg': {
              fontSize: '35px',
              [customTheme.breakpoints.up('sm')]: {
                fontSize: '28px',
                padding: '3px',
              },
              color: '#fff'
            }
          },
          title: {
            fontWeight: 'bold'
          }
        },
        MuiDrawer: {
          root: {
            [customTheme.breakpoints.up('sm')]: {
              background: '#cd2653'
            },
          },
        },
        MuiPopover: {
          paper: {
            '& ul[role="menu"]': {
              padding: '2px',
              backgroundColor: '#cd2653',
    
              '& .MuiMenuItem-root': {
                minHeight: '33px',
              }
            },
          },
        },
        MuiTableRow: {
          head: {
            color: '#000',
            fontWeight: '600',
            '& span': {
              color: '#000',
              fontWeight: '600'
            },
          }
        },
        MuiListItemIcon: {
          root: {
            color: '#fff',
          }
        },
        RaLogout: {
          menuItem: {
            color: '#fff',
          }
        },
        RaSidebar: {
          drawerPaper: {
            [customTheme.breakpoints.down('xs')]: {
              background: '#cd2653'
            },
          }
        },
        RaMenuItemLink: {
          root: {
            opacity: 0.8,
            color: '#fff'
          },
          active: {
            opacity: 1,
            color: '#fff'
          }
        },
        RaLayout: {
          content: {
            [customTheme.breakpoints.up('sm')]: {
              padding: '10px !important',
              marginBottom: '100px !important',
            },
          },
          appFrame: {
            marginTop: '109px !important',
    
            [customTheme.breakpoints.up('md')]: {
              marginTop: '83px !important',
              marginBottom: '100px !important',
              height: 'calc(100vh - 183px)',
            },
          },
          root: {
            [customTheme.breakpoints.down('md')]: {
              minHeight: 'calc(100vh - (160px + 91px))',
            },
          }
        },
        MuiTable: {
          root: {
            [customTheme.breakpoints.up('xs')]: {
              overflow: 'hidden'
            },
          }
        },
        MuiTableCell: {
          root: {
            whiteSpace: 'nowrap',
          }
        },
        MuiFormControl: {
          marginDense: {
            marginBottom: '8px',
    
            '& label': {
              marginBottom: '-8px'
            },
          }
        },
        MuiFilledInput: {
          root: {
            backgroundColor: '#edecec',
            borderTopRightRadius: '0px',
            borderTopLeftRadius: '0px',
          }
        },
        RaCreateButton: {
          floating: {
            bottom: '105px',
            [customTheme.breakpoints.down('sm')]: {
              bottom: '170px',
            },
          }
        },
        RaImageInput: {
          dropZone: {
            background: '#edecec',
            padding: '30px 8px',
            margin: '7px 0px',
            maxWidth: '384px'
          }
        },
        RaFormInput: {
          input: {
            width: '100%',
            [customTheme.breakpoints.up('sm')]: {
              width: '303px',
            },
            [customTheme.breakpoints.up('md')]: {
              width: '400px',
            },
          }
        },
      },
});

export default theme;