import {
  PaymentEdit,
  PaymentList,
  PaymentShow
} from './payments';

export default {
  list: PaymentList,
  show: PaymentShow,
  edit: PaymentEdit
};
