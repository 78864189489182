import React, {
  useEffect,
  useState
} from "react";

const AvatarField = (props) => {
  const userId = props.id;
  let [userAvatar, setUserAvatar] = useState('');
  let host = "444" === window.location.port ? `https://${document.domain}:8443` : `https://${document.domain}`;
  const apiUrl = `${host}`.replace('admin.', 'api.');
  const token = localStorage.getItem("token");

  const getUserAvatar = async() => {
    await fetch(`${apiUrl}/api/users/${userId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.avatar !== undefined) {
        setUserAvatar(apiUrl + data.avatar.fileUrl);
      }
    })
  }

  useEffect(() => {
    getUserAvatar();
  }, []);

  return (
    <div>
      {userAvatar !== '' ? <img src={`${userAvatar}`} alt="User avatar" style={{
          height: "150px",
          width: "150px",
          marginTop: "15px"
        }}/>
        : <div></div>}
    </div>
  )
}

export default AvatarField;
